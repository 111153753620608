import { APIErrorDetails, HttpApiCallErrorType } from "#shared/components/api/errors";
import { post } from "#shared/components/api/http";
import { getHeaders } from "#shared/components/api/utils";
import env from "#src/env";
import { APIResponse, Cookies } from "../../types/types";

type CompleteRegistrationProps = {
  briefRef: string;
  fingerprintId: string;
  cookies?: Cookies;
  origin?: string;
};
export default async function completeRegistration({
  fingerprintId,
  briefRef,
  cookies,
  origin,
}: CompleteRegistrationProps): Promise<APIResponse<any> | APIErrorDetails | undefined> {
  try {
    console.time(`${fingerprintId}-completeRegistration`);
    const response = await post<CompleteRegistrationProps, APIResponse<any>>(
      `${env.VITE_API_BASE_URL}/community/users-engagements/${briefRef}/complete-registration`,
      {
        briefRef,
        fingerprintId,
        origin,
      },
      { headers: getHeaders(cookies), credentials: "include" }
    );
    console.timeEnd(`${fingerprintId}-completeRegistration`);

    return response;
  } catch (e) {
    console.log("Error when completing UncompletedRegistration", e);
    return (e as HttpApiCallErrorType)?.errorDetails;
  }
}
